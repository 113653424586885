import React, { FC } from "react"

import SEO from "../components/seo"
import Heading from "../components/heading"
import Text from "../components/text"
import { PageProps } from "gatsby"

interface Props extends PageProps {}

const NotFoundPage: FC<Props> = () => (
  <>
    <SEO title={`404: Not found`} />
    <Heading as="h1" fontSize={8}>404: Not Found</Heading>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </>
)

export default NotFoundPage
